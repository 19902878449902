import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import { logout } from '../../services/auth';
import { setImg, getUsuario, setPassword, setDetailAPI, setTimezoneAPI } from '../../services/api';
import avatarDefault from '../../../src/utils/avatarGeneration';
import Default from '../../components/default';
import Banner from '../../components/banner';
import image from '../../assets/banner3.jpg';
import { FiLogOut } from "react-icons/fi";

function Profile() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [profileOption, setProfileOption] = useState('detail');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [detail, setDetail] = useState(null);
    const [timezone, setTimezone] = useState('null');
    const URL_PATCH = 'https://drive.google.com/uc?export=view&id=';

    useEffect(() => {
        getDataUser();

        if (!file) {
            setPreview(null);
            return
        }

        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)

    }, [file]);

    function getDataUser() {
        getUsuario('/user/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;
                const { user } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    var userResp = response.user[0];
                    setUser(userResp);
                    setDetail({
                        userName: userResp.userName,
                        userLastName: userResp.userLastName,
                        userEmail: userResp.userEmail
                    });
                    var timezone_ = user[0].timezone;
                    if (timezone_ === 'America/Sao_Paulo') {
                        setTimezone('br-sp');
                    } else {
                        setTimezone('pt-lb');
                    }
                }
            });
    }

    function handleSubmit(event) {
        switch (profileOption) {
            case 'detail': detail && detail.userName && detail.userLastName && detail.userEmail ? setDetailAPI('/user/details/', detail, () => { getDataUser() }) : alert('Preencher todos os detalhes primeiro');
                break;
            case 'avatar': file ? setImg('/assets/img/', file, () => { getDataUser(); setPreview(null); setFile(null) }) : alert('adicionar uma imagem primeiro');
                break;
            case 'password': setPassword('/user/password/', { NewPassword: newPassword || "root" }, () => { rulesFieldPassword() })
                break;
            case 'timezone':

                if (!timezone) {
                    alert('selecione um timezone primeiro');
                    return
                }

                var world_timezones = ['America/Sao_Paulo', 'Europe/Lisbon'];
                var timezone_action = timezone === 'br-sp' ? world_timezones[0] : world_timezones[1];

                setTimezoneAPI('/user/timezone/', { 'timezone': timezone_action }, () => { getDataUser(); navigate("/"); })

                break;
            // case 'theme':
            //     break;
        }
    }
    function handleImg(event) {
        if (!event.target.files || event.target.files.length === 0) {
            setFile(null);
            return
        }

        setFile(event.target.files[0]);
    }
    function handleOption(event) {
        var value = event.target.value;
        var option = '';
        switch (value) {
            case 'meus-detalhes': option = 'detail';
                break;
            case 'avatar': option = 'avatar';
                break;
            case 'senha': option = 'password';
                break;
            case 'tema': option = 'theme';
                break;
            case 'timezone': option = 'timezone';
                break;
            default: option = null;
        }

        setProfileOption(option);
    }
    function deleteImg() {
        setFile(null);
    }
    function handlePassword(event) {
        var value = event.target.value;
        setNewPassword(value);
    }
    function rulesFieldPassword() {
        if (newPassword === null || newPassword === '') {
            alert("Campo vazio, sua senha foi resetada para 'root'")
        } else {
            alert('Senha atualizada com sucesso');
        }
        setNewPassword('');
    }
    function handleDetail(key, value) {
        setDetail({
            ...detail,
            [key]: value
        });
    }

    return (
        <Default menu={{ option: "0" }}>
            <Banner image={image} title="Ajustes" size="2" />

            <div className="container-profile">
                <div className="container-avatar-name">
                    {user ?
                        <>
                            <img onClick={() => { setProfileOption('avatar') }} src={user && user.idAvatar ? URL_PATCH + user.idAvatar : avatarDefault()} />
                            {
                                user && user.userName && user.userLastName ? <h2>{user.userName} {user.userLastName}</h2> : null
                            }
                        </>
                        : null}
                    <span className="logout" onClick={() => {
                        logout();
                        navigate("/login/");
                    }}>Desconectar <FiLogOut /></span>

                </div>

                <div className="profile">
                    <div className="control-container" >

                        <select name="list" id="list" onChange={(event) => { handleOption(event) }}>
                            <option value="meus-detalhes">Meus detalhes</option>
                            <option value="avatar">Avatar</option>
                            <option value="senha">Senha</option>
                            <option value="timezone">Timezone</option>
                        </select>

                        <div className="list-menu-desk">
                            <ul>
                                <li onClick={() => { setProfileOption('detail') }} className={profileOption === 'detail' ? 'bg-color1' : null}>Meus detalhes</li>
                                <li onClick={() => { setProfileOption('avatar') }} className={profileOption === 'avatar' ? 'bg-color1' : null}>Avatar</li>
                                <li onClick={() => { setProfileOption('password') }} className={profileOption === 'password' ? 'bg-color1' : null}>Senha</li>
                                <li onClick={() => { setProfileOption('timezone') }} className={profileOption === 'timezone' ? 'bg-color1' : null}>Timezone</li>
                                {/* <li onClick={() => { setProfileOption('theme') }} className={profileOption === 'theme' ? 'bg-color1' : null}>Tema</li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="frame-container">

                        {
                            profileOption && profileOption === 'detail' ? (
                                <section id="detail">
                                    <h3>Atualize os detalhes do seu pefil</h3>
                                    <div className="input">
                                        <span>Primeiro Nome</span>

                                        <input type="text" placeholder="Nome" value={detail && detail.userName ? detail.userName : ''} onChange={event => { handleDetail('userName', event.target.value) }} />
                                    </div>
                                    <div className="input">
                                        <span>Sobrenome</span>

                                        <input type="text" placeholder="Sobrenome" value={detail && detail.userLastName ? detail.userLastName : ''} onChange={event => { handleDetail('userLastName', event.target.value) }} />
                                    </div>
                                    <div className="input">
                                        <span>Nome de usuario</span>

                                        <input type="text" placeholder="User" value={detail && detail.userEmail ? detail.userEmail : ''} onChange={event => { handleDetail('userEmail', event.target.value) }} />
                                    </div>
                                </section>
                            ) : null
                        }
                        {
                            profileOption && profileOption === 'password' ? (
                                <section id="password">
                                    <h3>Atualize sua senha</h3>
                                    <div className="input">
                                        <span>Nova senha</span>

                                        <input type="text" placeholder="Senha" value={newPassword} onChange={event => { handlePassword(event) }} />
                                    </div>
                                </section>
                            ) : null
                        }
                        {
                            profileOption && profileOption === 'timezone' ? (
                                <section id="timezone">
                                    <h3>Atualize seu timezone</h3>
                                    <div className="input">
                                        <span>timezone</span>

                                        <form>
                                            <div>
                                                <label for="br-sp">São Paulo - Brasil</label>
                                                <input type="radio" name="timezone" id="br-sp" value="São Paulo - Brasil" checked={timezone && timezone === 'br-sp' ? true : null} onClick={() => { setTimezone('br-sp') }} />
                                            </div>

                                            <div>
                                                <label for="pt-lb">Lisboa - Portugal</label>
                                                <input type="radio" name="timezone" id="pt-lb" value="Lisboa - Portugal" checked={timezone && timezone === 'pt-lb' ? true : null} onClick={() => { setTimezone('pt-lb') }} />
                                            </div>
                                        </form>
                                    </div>
                                </section>
                            ) : null
                        }
                        {/* {
                            profileOption && profileOption === 'theme' ? (
                                <section id="theme">
                                    <h3>Atualize seu tema</h3>
                                    <div className="input">
                                        <span>Tema</span>

                                        <input type="text" />
                                    </div>
                                </section>
                            ) : null
                        } */}
                        {
                            profileOption && profileOption === 'avatar' ? (
                                <section id="avatar">
                                    <h3>Atualize sua foto de pefil</h3>
                                    <div className="input">
                                        <span>Vamos exibir sua foto em seu perfil</span>

                                        <div className="upload-img">
                                            <label htmlFor="upload">
                                                <img src={preview ? preview : user && user.idAvatar ? URL_PATCH + user.idAvatar : avatarDefault()} />
                                            </label>

                                            <div className="action">
                                                {preview ? <span onClick={() => { deleteImg() }}>delete</span> : null}

                                                <label htmlFor="upload">
                                                    <span>upload</span>
                                                </label>
                                            </div>

                                            <input type="file" id="upload" accept="image/png, image/jpeg" onChange={(event) => { handleImg(event) }} />
                                        </div>
                                    </div>

                                    {preview ? <span className="info">imagem carregada, clique em salvar para enviar</span> : null}
                                </section>
                            ) : null
                        }

                        <button onClick={event => { handleSubmit(event) }}>Salvar</button>
                    </div>
                </div>
            </div>
        </Default>
    );
}

export default Profile;