import React from "react";
import './style.css';
import { Link } from 'react-router-dom';
// import PalpiteItemV2  from '../../components/palpiteItemV2';

function Error404() {
    return (
        <>
            {/* <PalpiteItemV2 nomeTime="time A"/> */}
            
            <h1>404 pagina não encontrada</h1>
            <Link to="/">ir para Inicio</Link>
        </>
    );
}

export default Error404;