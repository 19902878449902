import React from "react";
import './style.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel({ games }) {

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 700,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            },
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 414,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]

    };

    return (

        <div className="container-cads-game">
            <h3>Jogos da rodada</h3>


            <Slider {...settings}>
                {
                    games ? games.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="card-effect">
                                    <div className="card-games">
                                        <div className="times">
                                            <div className="time">
                                                <img src={item.times.timeA.urlLogo} alt="logo time"/>
                                                <span>{item.times.timeA.timeNome}</span>
                                            </div>
                                            <div className="time">
                                                <img src={item.times.timeB.urlLogo} alt="logo time"/>
                                                <span>{item.times.timeB.timeNome}</span>
                                            </div>
                                        </div>
                                        <div className="placar">
                                            <span>{item.resultados ? item.resultados.resultadoTimeA :'-'}</span>
                                            <span>{item.resultados ? item.resultados.resultadoTimeB :'-'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null
                }
            </Slider>
        </div>
    )
}

export default Carousel;