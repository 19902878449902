import React from "react";
import RoundScores from '../../../../components/roundScores';
import './style.css';

import { FiEdit } from "react-icons/fi";

function BetPreviewContainer({ setEdit, score, date, children, btnHide = false}) {
    return (
        <div className="preview">
            <>
                {
                    btnHide ? null : <button className="edit-button" onClick={setEdit}><FiEdit /></button>
                }
                <div className="preview-info">
                    <div className="context-info">
                        <span className="title">Jogos da rodada</span>
                        <span>{date}</span>
                    </div>
                    <RoundScores score={score} />
                    <div className="last-viewed">
                        <span>Editado em: </span>
                        <span>{date}</span>
                    </div>
                </div>

                <div className="bet-list">
                    {children}
                </div>
            </>
        </div>
    )
}

export default BetPreviewContainer;