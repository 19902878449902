import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Page404 from './pages/page404';
import Login from './pages/login';
import Bets from './pages/bets';
import Members from './pages/members';
import Profile from './pages/profile';
import Admin from './pages/admin';

function RoutesPage() {
    return (
        <BrowserRouter>                
            <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/login/" element={<Login />} />
                <Route path="/bets/" element={<Bets />} />
                <Route path="/members/" element={<Members />} />
                <Route path="/profile/" element={<Profile />} />
                <Route path="/admin/" element={<Admin />} />

                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesPage;