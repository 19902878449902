import React from "react";
import './style.css';
import avatarDefault from '../../../../utils/avatarGeneration';
import {statusOnline} from '../../../../utils/dateTransform';

function UserItem({ click, user, userId, timezonePrimary }) {
    const {
        userName,
        userLastName,
        somaPontos,
        somaAcertos,
        userLastViewed,
        idAvatar
    } = user;
    const URL = 'https://drive.google.com/uc?export=view&id=' + idAvatar;

    return (
        <>
            <div className="item" onClick={() => { click(userId) }}>
                <img src={idAvatar ? URL : avatarDefault()} alt="avatar" />

                <div className="context">
                    <div className="title">
                        <span className="title-item">nome</span>
                        <span>{userName} {userLastName}</span>
                    </div>

                    <div className="scores">
                        <div className="count-score">
                            <span className="title-item">soma de pontos</span>
                            <span>{somaPontos} pontos</span>
                        </div>
                        <div className="count-hits">
                            <span className="title-item">soma de acertos</span>
                            <span>{somaAcertos} acertos</span>
                        </div>
                    </div>
                </div>

                <div className="status">
                    <span style={{ color: "var("+statusOnline(userLastViewed).color+")" }}>{statusOnline(userLastViewed).name}</span>
                    <span className="dot" style={{ backgroundColor: "var("+statusOnline(userLastViewed).color+")" }}></span>
                </div>
            </div>
        </>
    )
}

export default UserItem;