const URL = 'https://drive.google.com/uc?export=view&id=';
var avatarId = [
    '1UEGmq3RwJST1hsd1hlbpuyofZ9-BSOiA',
    '1JPcX_jwJRoGJAgMlbGBAhyxijzi5fsl1',
    '1dg8ygBQlthB4pmab7o-2ZpX-3XwCera2',
    '1tIdwuV9pqlnpP0hRl6GB-xAjqNWL8bc7',
    '1EHQkwfoDDpfb-QOQSFVRdpJOTCQzuyvi',
    '1XZV3qKruUGifAPI73dSdi1hpOjGfPkwg',
    '1f4Lg8W05pfB_MlEsTifNlhQJjBijGcHs',
    '1sW7XtO4-6ti8I_WjUjFDxcSELRHYG5An',
    '14WNT_Z3zu_x7dubUyUJMq5FFt_GTNEpV'
];


function generationAvatarDefault(){
    var index = Math.floor(Math.random() * 9);
    return URL + avatarId[index];
}

export default generationAvatarDefault;