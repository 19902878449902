import React, { useEffect, useState } from "react";
import './style.css';

import { Link, useNavigate } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";

import { logout, removeRodada } from '../../services/auth';
import { getUsuario } from '../../services/api';
import routesReference from '../../utils/routesReference';
import { menuReference, highlight } from '../../utils/menuReference';


function Menu({ refClass, refClick, optionMenu }) {

    const [nomeUsuario, setNomeUsuario] = useState(null);
    const { _login } = routesReference;
    const navigate = useNavigate();

    useEffect(() => {
        getUsuario('/user/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate(_login);
                }

                if (status === 200) {
                    var [user] = [...response.user];
                    var nameUser = user.userName + " " + user.userLastName;
                    setNomeUsuario(nameUser);
                }
            });
    }, [])


    if (!nomeUsuario) {
        return null
    }

    return (
        <div className={refClass}>
            <header>
                <div>
                    <span>Bem vindo</span>
                    <span className="name">{nomeUsuario}</span>
                </div>

                <div className="btn-menu" onClick={() => { refClick() }}>
                    <FiChevronRight />
                </div>
            </header>

            <div className="container-list-menu">
                <nav>
                    <ul>
                        <li ><Link className="highlight" to={highlight.route}>{highlight.title}</Link></li>
                        {
                            menuReference.map((item, index) => {

                                if (optionMenu.option == index) {
                                    var selected = "selected";
                                }
                                return (
                                    <li><Link className={selected} to={item.route}>{item.title}</Link></li>
                                )
                            })
                        }
                    </ul>
                </nav>
            </div>

            <footer>
                <div className="logout" onClick={() => {
                    logout();
                    removeRodada();
                    navigate(_login);
                }}>
                    <FiLogOut />
                    <span>Sair</span>
                </div>
            </footer>
        </div>
    )
}

export default Menu;