import routesReference from '../utils/routesReference';

const { _home, _members, _bets, _dashboard, _profile } = routesReference;
const menuReference = [
    {
        route: _profile,
        title: "Ajustes"
    },
    {
        route: _members,
        title: "Membros"
    },
    {
        route: _home,
        title: "Inicio"
    }
];

const highlight = {
    route: _bets,
    title: "Pitacos"
}
export { menuReference, highlight };