import React from "react";
import './style.css';

function RoundScores({ score }) {
    return (
        <div className="round-score">
            <div className="context">
                <div className="title">
                    <span>Pontos da rodada</span>
                </div>
                <div>
                    <span>{score}</span>
                    <span>pts</span>
                </div>
            </div>
        </div>
    );
}

export default RoundScores;
