import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import { logout } from '../../services/auth';
import { getConfrontos, getRanking } from '../../services/api';

import Default from '../../components/default';
import Banner from '../../components/banner';
import image from '../../assets/banner3.jpg';
import CarouselBanner from './components/carousel-banner';
import CarouselGames from './components/carousel-games';
import Ranking from "./components/ranking";

function Home() {
    const navigate = useNavigate();

    useEffect(() => {

        getConfrontos('/confrontos/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;
                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    var confrontos = [...response.confrontos];
                    setGame(confrontos);

                }
            });    


        getRanking('/ranking/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    var palpites = [...response.ranking];
                    setRanking(palpites);
                }
            });

    }, []);


    const [ranking, setRanking] = useState(null);
    const [game, setGame] = useState(null);
    
    

    return (
        <section className="Home">
            <Default menu={{ option: "2" }}>
                <Banner image={image} ishome="true" HomeContext={CarouselBanner} size="2" />
                <CarouselGames games={game}/>
                <Ranking ranking={ranking} />
            </Default>
        </section>
    )
}

export default Home;