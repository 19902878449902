import React, { useState } from "react";
import './style.css';
import { FiChevronUp } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";

function BetItem({ titleTeamA, imgTeamA, titleTeamB, imgTeamB, idConfronto, defaultA, defaultB, palpiteID = null}) {

    const [count, setCount] = useState({
        idConfronto: idConfronto,
        timeA: defaultA || 0,
        timeB: defaultB || 0
    });

    function upCount(key) {
        if (count[key] < 10) {
            setCount({
                ...count,
                [key]: count[key] + 1
            });
        } else {
            setCount({
                ...count,
                [key]: 0
            });
        }
    }
    function downCount(key) {
        if (count[key] > 0) {
            setCount({
                ...count,
                [key]: count[key] - 1
            });
        } else {
            setCount({
                ...count,
                [key]: 10
            });
        }
    }
    function handleChange(key, value) {
        setCount({
            ...count,
            [key]: value
        });
    }
    var id = "bet" + idConfronto;

    return (
        <div className="bet-item-container" id={id}>

            <div className="club" onClick={() => { upCount("timeA") }}>
                <img src={imgTeamA} />
                <span className="title">{titleTeamA}</span>
            </div>

            <div className="control" palpiteID={palpiteID}>
                <div className="item">
                    <button onClick={() => { upCount("timeA") }}><FiChevronUp /></button>
                    <input type="text" id="timeA" placeholder="0" value={count["timeA"]} onChange={(e) => { handleChange("timeA", e.target.value) }} />
                    <button onClick={() => { downCount("timeA") }}><FiChevronDown /></button>
                </div>
                <span>vs</span>
                <div className="item">
                    <button onClick={() => { upCount("timeB") }}><FiChevronUp /></button>
                    <input type="text" id="timeB" placeholder="0" value={count["timeB"]} onChange={(e) => { handleChange("timeB", e.target.value) }} />
                    <button onClick={() => { downCount("timeB") }}><FiChevronDown /></button>
                </div>
            </div>

            <div className="club" onClick={() => { upCount("timeB") }}>
                <img src={imgTeamB} />
                <span className="title">{titleTeamB}</span>
            </div>
        </div>
    )
}

export default BetItem;