import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';
import Logo from '../../assets/logo.png';
import VideoMlks from '../../assets/coverr-friends-playing-on-the-beach-8962-1080p.mp4';
import { isAuthenticated } from '../../services/auth';
import { setLogin } from '../../services/api';

function Login() {

    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [password, setPassword] = useState();

    useEffect(() => {
        if (isAuthenticated()) {
            navigate("/");
        }
    }, [])

    function handleSubmit() {        
        if (user && password) {
            setLogin('/authenticator/', { user, password }, () => { navigate("/") });
        } else {
            alert("Informe usuario e senha")
        }
    }

    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'user') {
            setUser(value);
        }

        if (name === 'password') {
            setPassword(value);
        }
    }

    return (
        <section className="sectionLogin">
            <video autoplay="autoplay" muted="muted" loop="loop" >
                <source src={VideoMlks} type="video/mp4" />
            </video>
            <div className="container-login">
                <div className="logo">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="frame">
                    <div className="title">
                        <h1>Bem vindo ao Champs</h1>
                    </div>
                    <form>
                        <div className="input-item">
                            <span>Usuario</span>
                            <input type="text" autocomplete="off" name="user" value={user} onChange={(event) => { handleChange(event) }} />
                        </div>
                        <div className="input-item">
                            <span>Senha</span>
                            <input type="password" autocomplete="off" name="password" value={password} onChange={(event) => { handleChange(event) }} />
                        </div>
                        <input type="button" value="Entrar" onClick={handleSubmit} />
                        <p>apenas para membros</p>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Login;