import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import routesReference from "../../utils/routesReference";

import './style.css';
import { FiX } from "react-icons/fi";
import { logout } from '../../services/auth';
import { getConfrontos, setPitaco, setPUTPitaco, getRound, getPalpitesResultados } from '../../services/api';

import Default from '../../components/default';
import Banner from '../../components/banner';

import BetItem from '../../components/betItem';
import BetPreviewItem from '../../components/betPreviewItem';
import BetContainer from './components/betContainer';
import BetPreviewContainer from './components/betPreviewContainer';

import { transform } from '../../utils/dateTransform';
import image from '../../assets/banner1.jpg';
import sad from '../../assets/sad.svg';

function Bets() {
    const navigate = useNavigate();

    useEffect(() => {

        // validação se janela aberta ou fechada e se foi lancado palpites ou não
        getRound('/round/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                const validate = {
                    isRoundOpen: false,
                    hasBetValue: false
                };

                if (status === 200) {
                    var roundStatus = response.roundStatus;
                    var round = response;
                    setRound(round);

                    if (roundStatus === 'open') {
                        validate.isRoundOpen = true;
                    }

                    //buscar resultados de palpite
                    getPalpitesResultados('/palpite/resultados/')
                        .then((resp) => resp.json())
                        .then((response) => {
                            const { confrontosPalpites } = response;
                            const { status } = response;
                            const { error } = response;

                            if (status === 401) {
                                if (error !== "No rodadaID") {
                                    logout();
                                    navigate("/login/");
                                }
                            }



                            if (status === 200) {

                                var confrontos = [...confrontosPalpites];

                                var hasBetValue = confrontos.filter((item) => {
                                    return item.palpites;
                                })
                                var somaPontosRodada = 0;

                                // eslint-disable-next-line
                                confrontos.map((item) => {
                                    if (item.palpites) {
                                        somaPontosRodada = somaPontosRodada + item.palpites.pontos;
                                    }
                                })

                                response.somaPontosRodada = somaPontosRodada;

                                if (hasBetValue.length > 0) {
                                    validate.hasBetValue = true;

                                    var date = transform(response.updated.updated);
                                    response.updated = date;
                                    setResults(response);
                                } else {
                                    setResults(null);
                                }

                                validateRound(validate);
                            }
                        });

                    // jogos da rodada
                    getConfrontos('/confrontos/')
                        .then((resp) => resp.json())
                        .then((response) => {
                            const { status } = response;

                            if (status === 401) {
                                logout();
                                navigate("/login/");
                            }

                            if (status === 200) {
                                var confrontos = [...response.confrontos];
                                setConfrontos(confrontos);
                            }
                        });
                }
            });
    }, [navigate]);

    const [status, setStatus] = useState('novo');
    const [round, setRound] = useState(null);
    const [results, setResults] = useState(null);
    const [confrontos, setConfrontos] = useState(null);

    const [modal, setModal] = useState("modal dn");
    const [betItem, setBetItem] = useState(null);
    const [btnEditHide, setBtnEditHide] = useState(null);
    const [palpites, setPalpites] = useState([]);

    function apiGetPalpitesResultados() {
        getPalpitesResultados('/palpite/resultados/')
            .then((resp) => resp.json())
            .then((response) => {
                const { confrontosPalpites } = response;
                const { status } = response;
                const { error } = response;

                if (status === 401) {
                    if (error !== "No rodadaID") {
                        logout();
                        navigate("/login/");
                    }
                }

                if (status === 200) {
                    var confrontos = [...confrontosPalpites];

                    var hasBetValue = confrontos.filter((item) => {
                        return item.palpites;
                    });

                    var somaPontosRodada = 0;
                    // eslint-disable-next-line 
                    confrontos.map((item) => {
                        if (item.palpites) {
                            somaPontosRodada = somaPontosRodada + item.palpites.pontos;
                        }
                    })

                    response.somaPontosRodada = somaPontosRodada;

                    if (hasBetValue.length > 0) {
                        var date = transform(response.updated.updated);
                        response.updated = date;
                        setResults(response);
                    } else {
                        setResults(null);
                    }

                }
            });
    }

    function validateRound({ isRoundOpen, hasBetValue }) {
        if (isRoundOpen) { //se janela aberta
            if (hasBetValue) { // se eu ja lancei pitacos
                setBetItem(2);  // exibi modulo 2 com botão de editar
                setStatus('editar');
                setBtnEditHide(false); // exibir botão de editar
            } else { // se aberto mas não lancei ainda                
                setBetItem(1);  // mostra o modulo 1
                setStatus('novo');
            }
        } else { // se estiver fechado

            setBetItem(2); // exibindo modulo 2
            setBtnEditHide(true); // ocultando botão de editar
            setStatus('novo');

        }
    }
    function closeModal() {
        setModal("modal dn")
    }
    function openModal() {
        setModal("modal")
    }

    function getCountAll() {
        var all = [];
        // eslint-disable-next-line 
        confrontos.map((item) => {
            // eslint-disable-next-line 
            var queryA = '#bet' + item.confrontoId + ' ' + 'input#timeA';
            // eslint-disable-next-line 
            var queryB = '#bet' + item.confrontoId + ' ' + 'input#timeB';

            all.push({
                confrontoID: item.confrontoId,
                palpiteA: parseInt(document.querySelector(queryA).value),
                palpiteB: parseInt(document.querySelector(queryB).value)
            })
        });

        setPalpites(all)
    }
    function getCountAllUpdate() {
        var all = [];

        // eslint-disable-next-line 
        results.confrontosPalpites.map((item) => {
            // eslint-disable-next-line 
            var queryPid = '#bet' + item.confrontoId + ' ' + '.control';
            // eslint-disable-next-line 
            var queryA = '#bet' + item.confrontoId + ' ' + 'input#timeA';
            // eslint-disable-next-line 
            var queryB = '#bet' + item.confrontoId + ' ' + 'input#timeB';

            all.push({
                id: parseInt(document.querySelector(queryPid).getAttribute('palpiteid')),
                palpiteA: parseInt(document.querySelector(queryA).value),
                palpiteB: parseInt(document.querySelector(queryB).value)
            })
        });

        setPalpites(all)
    }

    function setConfirm() {
        if (status === 'novo') {
            getCountAll();
        }

        if (status === 'editar') {
            getCountAllUpdate();
        }

        openModal();
    }

    function setSubmit() {
        if (status === 'novo') {
            setPitaco('/palpite/', palpites, () => {
                apiGetPalpitesResultados();
                setStatus('editar');
            });
        }

        if (status === 'editar') {
            setPUTPitaco('/palpite/', palpites, () => {
                apiGetPalpitesResultados();
            });
        }

        setModal("modal dn");
        setBetItem(2);
    }



    return (
        <Default menu={{ option: "4" }}>
            <Banner image={image} title="Meus palpites" size="0" />
            <div className="bets-container">

                {
                    betItem === 1 ? (
                        <BetContainer setConfirm={setConfirm} date={round.date}>
                            {
                                confrontos && status === 'novo' ?
                                    confrontos.map((item) => {
                                        return (
                                            <BetItem
                                                titleTeamA={item.times.timeA.timeNome}
                                                titleTeamB={item.times.timeB.timeNome}
                                                imgTeamA={item.times.timeA.urlLogo}
                                                imgTeamB={item.times.timeB.urlLogo}
                                                idConfronto={item.confrontoId}
                                                key={item.confrontoId}
                                            />
                                        )
                                    })
                                    : null
                            }
                            {
                                results && status === 'editar' ?
                                    results.confrontosPalpites.map((item) => {
                                        return (
                                            <BetItem
                                                titleTeamA={item.times.timeA.timeNome}
                                                titleTeamB={item.times.timeB.timeNome}
                                                imgTeamA={item.times.timeA.urlLogo}
                                                imgTeamB={item.times.timeB.urlLogo}
                                                idConfronto={item.confrontoId}
                                                key={item.confrontoId}
                                                defaultA={item.palpites.palpiteA}
                                                defaultB={item.palpites.palpiteB}
                                                palpiteID={item.palpites.id}
                                            />
                                        )
                                    })
                                    : null
                            }

                        </BetContainer>
                    ) : null
                }
                {
                    betItem === 2 ?

                        results ? (
                            <BetPreviewContainer btnHide={btnEditHide} date={results.updated || '-----'} score={results.somaPontosRodada || '---'} setEdit={() => { apiGetPalpitesResultados(); setBetItem(1) }}>

                                {// eslint-disable-next-line 
                                    results ? results.confrontosPalpites.map((item) => {
                                        if (item.palpites) {
                                            return (<BetPreviewItem
                                                titleTeamA={item.times.timeA.timeNome}
                                                titleTeamB={item.times.timeB.timeNome}
                                                imgTeamA={item.times.timeA.urlLogo}
                                                imgTeamB={item.times.timeB.urlLogo}
                                                resultA={item.palpites.palpiteA}
                                                resultB={item.palpites.palpiteB}
                                                score={item.palpites.pontos}
                                                idRound={item.palpites.rodadaID} />)
                                        }
                                    }) : null
                                }
                            </BetPreviewContainer>
                        ) : <div className="aviso">
                            <p>Que pena que não consegui lançar seus pitacos antes de finalizar o prazo<br /> aguarde a proxima rodada</p>
                            <img src={sad} />
                            <Link to={routesReference._home}>voltar para o inicio</Link>
                        </div>



                        : null
                }
            </div>


            <div className={modal}>
                <div className="container">
                    <div className="modal-header">
                        <span>Novidades</span>
                        <FiX onClick={closeModal} />
                    </div>

                    <h4>Opa, agora você pode editar!</h4>
                    <p>Edite seus palpites, quantas vezes quiser, enquanto a prazo para palites estiver aberta.</p>
                    <button onClick={setSubmit}>Lançar Pitaco</button>
                </div>
            </div>
        </Default>
    );
}

export default Bets;