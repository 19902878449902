function transform(dateCode) {
    const monthsOfYear = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    const week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado'];

    var date = new Date(dateCode);
    var year = date.getFullYear(); // 2022
    var month = monthsOfYear[date.getMonth()]; //4
    var dayWeek = week[date.getDay()]; // 0 a 6
    var day = date.getDate(); // 12
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var S = ' '; //space

    return dayWeek + S + day + ' de ' + month + ' às ' + hours + 'h' + minutes + 'm';
}

function statusOnline(date) {

    var status = {};

    if (date) {
        var data = date.split(" ")[0];
        data = date.split("/");
        
        var hora = date.split(" ")[1]
        var dia = data[0];
        var mes = data[1];
        var ano = data[2];
        ano = ano.split(",")[0];
        var dataFinal = ano + "/" + mes + "/" + dia + " " + hora
        
        var dateLastActivity = new Date(dataFinal);
        var dateNow = new Date();
        
        var between = new Date(dateNow - dateLastActivity);

        var minutes = between.getUTCMinutes();
        var hour = between.getUTCHours();
   
        if (date === null) {
            status.name = 'offline';
            status.color = '--color3';
            return status
        }

        if (hour > 0) {
            status.name = 'offline';
            status.color = '--color3';
            return status
        }

        if (minutes < 2) {
            //online
            status.name = 'online';
            status.color = '--color2';
        }

        if (minutes > 2 && minutes < 30) {
            // ausente
            status.name = 'ausente';
            status.color = '--color1';
        }

        if (minutes > 30) {
            // offline
            status.name = 'offline';
            status.color = '--color3';
        }
    }

    return status
}

export { transform, statusOnline };