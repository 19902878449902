import React from "react";

function BetContainer({ setConfirm, date, children }) {
    return (
        <>
            <div className="bet-info">
                <div>
                    <span className="title">Jogos da rodada</span>
                    <span>{date.dia1}</span>
                    <span>{date.dia2}</span>
                </div>

                <div className="container-confirm confirm-desk">
                    <button onClick={setConfirm}>Confirmar Palpites</button>
                </div>
            </div>

            <div className="bet-list">
                {children}
            </div>

            <div className="container-confirm confirm-mobile">
                <button onClick={setConfirm}>Confirmar Palpites</button>
            </div>
        </>
    )
}

export default BetContainer;