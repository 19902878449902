import React from "react";
import './style.css';

function BetPreviewItem({ titleTeamA, imgTeamA, titleTeamB, imgTeamB, idRound, resultA, resultB, score }) {

    return (
        <div className="bet-item-preview-container">
            <div className="club">
                <img src={imgTeamA} />
                <span className="title">{titleTeamA}</span>
            </div>

            <div className="control">
                <div className="item">
                   <span className="number">{resultA}</span>
                </div>
                <span>vs</span>
                <div className="item">
                   <span className="number">{resultB}</span>
                </div>
            </div>

            <div className="club">
                <img src={imgTeamB} />
                <span className="title">{titleTeamB}</span>
            </div>

            <div className="score">
                <span>{score} pts</span>
            </div>
        </div>
    )
}

export default BetPreviewItem;