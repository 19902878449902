import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import Header from '../header';
import Board from '../board';
import { isAuthenticated, logout, setRodada, removeRodada } from '../../services/auth';
import { getUsuario } from '../../services/api';

function Default({ menu, children }) {

    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated()) {
            navigate("/login/");
        }


        // verificar se o existe rodada!
        getUsuario('/user/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;
                const { rodada } = response;
                
                // verificar se tem o token ta valido!
                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if(rodada == false){
                    removeRodada();
                }
                
                if(rodada){
                    setRodada(parseInt(rodada.id));
                }

            });

    });


    return (
        <div data-theme="default" className="default-component">
            <div className="default-container">
                <Header optionMenu={menu} />
                <Board />
            </div>

            {children}
        </div>
    )
}

export default Default;

