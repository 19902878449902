import React, { useEffect } from "react";
import { useState } from "react";
import { getUsuario, getRound } from '../../services/api';
import { logout } from '../../services/auth';
import { useNavigate } from "react-router-dom";
import './style.css';

function Board() {
    const [pontos, setPontos] = useState(null);
    const [round, setRound] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // verificar se o existe rodada!
        getUsuario('/user/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;
                const { user } = response;

                // verificar se tem o token ta valido!
                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    setPontos(parseInt(user[0].somaPontos));
                }
            });


        getRound('/round/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;
                const { roundStatus } = response;

                // verificar se tem o token ta valido!
                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    const roundObj = {};
                    if (roundStatus === 'open') {
                        const { date } = response;
                        
                        roundObj.status = 'open';
                        roundObj.date = date.dia1;
                    } else {
                        roundObj.status = 'closed';
                    }

                    setRound(roundObj);
                }
            });
    }, [])
    return (
        <div className="board-container">
            <div className="board">
                <div className="frame">
                    {
                        round && round.status === 'open' ? (
                            <>
                                <h4>Prazo para palpites</h4>
                                <span>{round.date}</span>
                            </>
                        ) : null
                    }
                    {
                        round && round.status === 'closed' ? (
                            <h4>Janela para palpites fechada!</h4>
                        ) : null
                    }
                </div>
                <span className="line"></span>
                <div className="score">
                    <h4>Pontos geral</h4>
                    {
                        pontos ? (
                            <span>{pontos} pts</span>
                        ) : <span>-- pts</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default Board;