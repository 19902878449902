import React from "react";
import './style.css';

function Banner({ image, title, size, ishome = false, HomeContext }) {

    const classSize = [
        "banner-container banner",
        "banner-container banner1",
        "banner-container banner2"
    ];

    return (
        <div className={classSize[size]}>

            {ishome ? null : (<div
                style={{ backgroundImage: 'url(' + image + ')' }}
                className="bg-banner"
            ></div>)}
            {ishome ? (<HomeContext />) : <h1>{title}</h1>}
        </div>
    )
}

export default Banner;