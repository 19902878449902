import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import { getConfrontos } from '../../services/api';
import { setAdminResultadoJogos, getPontosAction, getUsuario, getDatas, getTimes, setConfrontosAPI } from '../../services/api';
import { isAuthenticated, logout } from '../../services/auth';

function Admin() {
    const navigate = useNavigate();

    const [confrontos, setConfrontos] = useState([]);
    const [datas, setDatas] = useState(null);
    const [times, setTimes] = useState(null);

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate("/login/");
        }

        getUsuario('/user/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    var [user] = [...response.user];

                    if (user.id != 1) {
                        navigate("/");
                    }
                }
            });
        getTimes('/admin/times/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    var times = response.times;
                    setTimes(times);
                }
            });
        getDatas('/admin/datas/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    var datas = response.datas;
                    setDatas(datas);
                }
            });
    }, []);

    function getData() {
        getConfrontos('/confrontos/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    alert('401')
                }

                if (status === 200) {
                    var confrontos = [...response.confrontos];
                    setConfrontos(confrontos);
                }
            });
    }

    useEffect(() => {
        getData();
    }, []);


    function handleSubmitResultado() {
        const form = document.forms.result;
        const resultado = [];

        confrontos.map((item) => {

            if (!item.resultados) {
                var timeA = "TimeAResultado" + item.confrontoId;
                var timeB = "TimeBResultado" + item.confrontoId;

                if (form[timeA].value && form[timeB].value) {
                    resultado.push({
                        confrontoID: item.confrontoId,
                        resultadoTimeA: form[timeA].value,
                        resultadoTimeB: form[timeB].value,
                        confrontoData: item.confrontoData
                    });
                }
            }
        });

        setAdminResultadoJogos('/resultado/partidas/', resultado);
        getData();
    }

    function handleSubmitPontos() {
        getPontosAction('/pontos/');
        alert('Pontos computados')
    }
    function handleSubmitPontosRodada() {
        getPontosAction('/pontos/rodada/');
        alert('Pontos calculados para as rodadas')
    }
    function handleSubmitRankingPontoAcertos() {
        getPontosAction('/ranking/update/');
        alert('Pontos geral calculados para cada usuario e acertos')
    }



    const [selectTimes, setSelectTimes] = useState(null);
    const [selectData, setSelectData] = useState(null);
    const [selectHour, setSelectHour] = useState(null);
    const [createConfrontoList, setCreateConfrontoList] = useState([]);

    function handleSelecTimes(time, id) {
        setSelectTimes({
            ...selectTimes,
            [time]: id
        });
    }
    function handleSelecData(data) {
        setSelectData(data);
    }
    function handleSelecHour(hour) {
        setSelectHour(hour);
    }

    function adicionarConfronto() {
        setCreateConfrontoList([
            ...createConfrontoList,
            {
                ...selectTimes,
                confrontoData: selectData + ' - ' + selectHour
            }
        ]);
        setSelectTimes(null);
    }

    function lancarConfrontos() {
        setConfrontosAPI('/confrontos/',createConfrontoList, ()=>{alert('lançado os confrontos'); setCreateConfrontoList(null)})
    }
    return (
        <section className="admin">

            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Lançamento de resultado da rodada
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <section className="container-resultados">
                                <h2>Lançamento de resultado da rodada</h2>
                                <form name="result">
                                    <div className="resultado-admin">
                                        {
                                            confrontos.map((item) => {

                                                var nameA = "TimeAResultado" + item.confrontoId;
                                                var nameB = "TimeBResultado" + item.confrontoId;
                                                return (
                                                    <>
                                                        {
                                                            item.resultados ? null : (
                                                                <div className="resultado-admin-item">
                                                                    <div>
                                                                        id:  {item.confrontoId}
                                                                    </div>

                                                                    <div className="confronto">
                                                                        <div>
                                                                            {item.times.timeA.timeNome}
                                                                            <input type="text" name={nameA} />
                                                                        </div>
                                                                        <span> vs </span>
                                                                        <div>
                                                                            {item.times.timeB.timeNome}
                                                                            <input type="text" name={nameB} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        data: {item.confrontoData}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                </form>
                                <button type="button" class="btn btn-success" onClick={() => { handleSubmitResultado() }}>Lançar resultado</button>
                            </section>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Pontos Action
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <section className="pontos-container">
                                <div>
                                    <h3>Etapa 1</h3>
                                    <p>Gerar pontos individuais nos usuarios</p>
                                    <button type="button" class="btn btn-warning" onClick={() => { handleSubmitPontos() }}>Gerar Pontos individuais</button>
                                </div>
                                <div className="mt-4">
                                    <h3>Etapa 2</h3>
                                    <p>Somar pontos rodada dos usuarios</p>
                                    <button type="button" class="btn btn-warning" onClick={() => { handleSubmitPontosRodada() }}>Somar pontos rodada</button>
                                </div>
                                <div className="mt-4">
                                    <h3>Etapa 3</h3>
                                    <p>Somar pontos geral e acertos - Ranking</p>
                                    <button type="button" class="btn btn-warning" onClick={() => { handleSubmitRankingPontoAcertos() }}>Atualizar ranking e acertos geral</button>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>



            <section className="criar-confronto">
                <div>
                    <h4>Registrar confronto</h4>

                    <div className="times_lista">
                        <div>
                            <h4>time A</h4>
                            <select name="times" onChange={(event) => { handleSelecTimes('timeAID', event.target.value) }}>
                                <option value="--inicio--">--inicio--</option>
                                {
                                    times ? times.map((item) => {
                                        return <option value={item.id}>{item.timeNome}</option>
                                    }) : <option value="vazio">vazio</option>
                                }
                            </select>
                        </div>
                        <div>
                            <h4>time B</h4>
                            <select name="times" onChange={(event) => { handleSelecTimes('timeBID', event.target.value) }}>
                                <option value="--inicio--">--inicio--</option>
                                {
                                    times ? times.map((item) => {
                                        return <option value={item.id}>{item.timeNome}</option>
                                    }) : <option value="vazio">vazio</option>
                                }
                            </select>
                        </div>
                    </div>

                    <div>
                        <h4>Datas</h4>
                        <select name="datas" onChange={(event) => { handleSelecData(event.target.value) }}>
                            <option value="--inicio--">--inicio--</option>
                            {
                                datas ? datas.map((item) => {
                                    return <option value={item.dataConfrontos}>{item.dataConfrontos}</option>
                                }) : <option value="vazio">vazio</option>
                            }
                        </select>
                    </div>

                    <div>
                        <h4>Horario</h4>
                        <select name="horario" onChange={(event) => { handleSelecHour(event.target.value) }}>
                            <option value="--inicio--">--inicio--</option>                            
                            <option value="13 horas">13 horas</option>                            
                            <option value="14 horas">14 horas</option>                            
                            <option value="14:45 horas">14:45 horas</option>                            
                            <option value="16 horas">16 horas</option>                            
                            <option value="17 horas">17 horas</option>                            
                        </select>
                    </div>

                    <button onClick={() => { adicionarConfronto() }}>adicionar</button>
                    <button onClick={() => { lancarConfrontos() }}>lançar</button>
                </div>
                <div>
                    <div className="list">
                        <h4>preview JSON</h4>
                        {
                            createConfrontoList ? createConfrontoList.map((item) => {

                                return (
                                    <div className="item">
                                        <span>timeAID: {item.timeAID}</span>
                                        <span>timeBID: {item.timeBID}</span>
                                        <span>confrontoData: {item.confrontoData}</span>
                                        <span>=========================</span>
                                    </div>
                                )


                            }) : null
                        }
                    </div>
                </div>
            </section>

        </section>
    )

}

export default Admin;