export const TOKEN_KEY = "@champs-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = tokenValue => {
  var token = "Bearer " + tokenValue;
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};


export const RODADA = "@champs-Rodada";
export const isRodada = () => localStorage.getItem(RODADA) !== null;
export const getRodada = () => localStorage.getItem(RODADA);
export const setRodada = rodadaId => {
  localStorage.setItem(RODADA, rodadaId);
};
export const removeRodada = () => {
  localStorage.removeItem(RODADA);
};