import React from "react";
import './style.css';
import { FiSearch } from "react-icons/fi";

function SearchUsers({search}) {
    return (
        <>
            <div className="search-container">
                <div className="search">
                    <FiSearch />
                    <input type="text" placeholder="Search Members" onChange={(event)=>{search(event.target.value)}} />
                </div>
            </div>
        </>
    );
}

export default SearchUsers;