import { login, logout, getToken, getRodada, setRodada, removeRodada } from './auth';

// export const HOST = "http://localhost:3333";
export const HOST = "https://champs-backend-production.up.railway.app";
export const setLogin = (endpoint, credential, redirect) => {
    const url = HOST + endpoint;
    const { user, password } = credential;
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
            userEmail: user,
            userPassword: password
        })
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;   

            if (status === 200) {
                const { token } = response;
                const { rodada } = response;
                login(token);

                if (rodada.isActive == true) {
                    setRodada(rodada.rodadaId);
                }

                if (rodada.isActive == false) {
                    removeRodada();
                }

                redirect();
            }

            if (status === 400) {
                alert('Usuario não encontrado');
            }

            if (status === 401) {
                alert('Senha Invalida');
            }
        })
}

export const getUsuario = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}
export const getDatas = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}
export const getTimes = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}
export const getConfrontos = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}

export const getRound = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}
export const getMembers = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}


export const getPalpitesResultados = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const rodadaID = getRodada();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token, "rodadaid": rodadaID }
    }

    return fetch(url, config);
}

export const getRanking = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}

export const setPitaco = (endpoint, palpites, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(palpites)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {
                var rodadaID = response.rodadaID;
                setRodada(rodadaID);
                next();
            }
        })
}
export const setConfrontosAPI = (endpoint, confronto, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(confronto)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {
                next();
            }
        })
}
export const setDetailAPI = (endpoint, details, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(details)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {               
                next();
                alert('Detalhes atualizados com sucesso')
            }
        })
}
export const setPassword = (endpoint, password, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(password)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {               
                next();
            }
        })
}

export const setTimezoneAPI = (endpoint, timezone, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(timezone)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {               
                next();
                alert('Timezone atualizado com sucesso');
            }
        })
}
export const setImg = (endpoint, file, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const formData = new FormData();
    formData.append('avatar', file);
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "authorization": token }, //"Content-type": "multipart/form-data; boundary=<calculated when request is sent>", 
        body: formData
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {
                next();
                alert('imagem atualizada com sucesso');
            }
        })
}

export const setPUTPitaco = (endpoint, palpites, next) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'PUT',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(palpites)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {
                next();
            }
        })
}

export const setAdminResultadoJogos = (endpoint, palpites) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { "Content-type": "application/json", "authorization": token },
        body: JSON.stringify(palpites)
    }

    fetch(url, config)
        .then((resp) => resp.json())
        .then((response) => {
            const { status } = response;

            if (status === 200) {
                alert("Jogos salvos");
            }
        })
}


export const getPontosAction = (endpoint) => {
    const url = HOST + endpoint;
    const token = getToken();
    const config = {
        method: 'GET',
        headers: { "Content-type": "application/json", "authorization": token }
    }

    return fetch(url, config);
}