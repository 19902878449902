import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import { logout } from '../../services/auth';
import { getMembers } from '../../services/api';
import avatarDefault from '../../../src/utils/avatarGeneration';
import { statusOnline } from '../../../src/utils/dateTransform';


import Default from '../../components/default';
import Banner from '../../components/banner';
import image from '../../assets/banner2.jpg';
import UserItem from './components/userItem';
import SearchUsers from './components/searchUsers';
import BetPreviewItem from '../../components/betPreviewItem';
import { FiX } from "react-icons/fi";

function Bets() {
    const navigate = useNavigate();

    const [member, setMember] = useState(null);
    const [members, setMembers] = useState(null);
    const [memberSearch, setMemberSearch] = useState(null);
    const [mobileHide, setMobileHide] = useState('context-container mobileHide');

    useEffect(() => {
        getMembers('/members/')
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;
                const { user } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    setMembers(user);
                }
            });
    }, []);

    function _getDataMember(id) {
        var endPoint = '/members/' + id;

        getMembers(endPoint)
            .then((resp) => resp.json())
            .then((response) => {
                const { status } = response;

                if (status === 401) {
                    logout();
                    navigate("/login/");
                }

                if (status === 200) {
                    setMember(response);
                }

                if (status === 416) {
                    const { user } = response;

                    setMember({ user: user });
                }


            });
    }

    function toggleHide() {
        if (mobileHide.includes('mobileHide')) {
            setMobileHide('context-container');
        } else {
            setMobileHide('context-container mobileHide');
        }
    }

    function selectedUser(userId) {
        _getDataMember(userId);
        toggleHide();
    }

    function searchChange(value) {
        var search = value;
        var list = [];
        if (members) {
            console.log('entrou')

            members.map((item) => {
                var name = item.userName + ' ' + item.userLastName;
                var userEmail = item.userEmail;

                if (name.includes(search) || userEmail.includes(search)) {
                    list.push(item);
                }
            })
        }

        setMemberSearch(list);
    }

    const URL = 'https://drive.google.com/uc?export=view&id=';

    return (
        <Default menu={{ option: "1" }}>
            <Banner image={image} title="Membros" size="1" />

            <div className="members-container">

                <div className="list-users-container">
                    <SearchUsers search={searchChange} />

                    <div className="list-users">
                        {
                            memberSearch ? memberSearch.map((item) => {
                                return (
                                    <UserItem click={() => { selectedUser(parseInt(item.id)) }} user={item} userId={item.id} timezonePrimary={member && member.user[0] ? member.user[0].timezone : null}/>
                                )
                            }) : members ? members.map((item) => {
                                return (
                                    <UserItem click={() => { selectedUser(parseInt(item.id)) }} user={item} userId={item.id} timezonePrimary={member && member.user[0] ? member.user[0].timezone : null}/>
                                )
                            }) : null
                        }
                    </div>
                </div>


                <div className={mobileHide}>
                    <div className="info-user">
                        <div className="status">
                            <span style={{ color: "var(" + statusOnline(member && member.user[0] ? member.user[0].userLastViewed : null).color + ")" }}> {statusOnline(member && member.user[0] ? member.user[0].userLastViewed : null).name}</span>
                            <span className="dot" style={{ backgroundColor: "var(" + statusOnline(member && member.user[0] ? member.user[0].userLastViewed : null).color + ")" }}></span>
                        </div>
                        <div className="user">

                            <img src={member && member.user[0].idAvatar ? URL + member.user[0].idAvatar : avatarDefault()} alt="avatar" />

                            <div className="context">
                                <div className="title">
                                    <span className="title-item">nome</span>
                                    <span>{member ? member.user[0].userName + ' ' + member.user[0].userLastName : '---'}</span>
                                </div>

                                <div className="scores">
                                    <div className="count-score">
                                        <span className="title-item">pontos da rodada</span>
                                        <span>{member && member.pontosRodada ? member.pontosRodada : '--'} pontos</span>
                                    </div>
                                    <div className="count-hits">
                                        <span className="title-item">acertos</span>
                                        <span>{member && member.acertosRodada ? member.acertosRodada : '--'} acertos</span>
                                    </div>
                                </div>
                            </div>

                            <FiX onClick={() => { toggleHide() }} />
                        </div>

                        <span className="title">Pitacos da rodada</span>
                    </div>
                    {
                        member && member.confrontosPalpites ? member.confrontosPalpites.map((item) => {
                            var times = item.times;
                            var palpites = item.palpites;

                            return (
                                <BetPreviewItem
                                    titleTeamA={times.timeA.timeNome}
                                    titleTeamB={times.timeB.timeNome}
                                    imgTeamA={times.timeA.urlLogo}
                                    imgTeamB={times.timeB.urlLogo}
                                    resultA={palpites.palpiteA}
                                    resultB={palpites.palpiteB}
                                    score={palpites.pontos}
                                />
                            );
                        }) : 'palpites não lançados'
                    }

                </div>

            </div>
        </Default>
    );
}

export default Bets;