import React from "react";
import { Link } from "react-router-dom";
import routesReference from "../../../../utils/routesReference";
import './style.css';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Carousel() {

    const { _members, _bets } = routesReference;
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 900,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: false,
      centerPadding: true
    };

    return (

        <div className="carousel-banner">
            <h2>Bem vindo mestre!</h2>

            <Slider {...settings}>
                <div className="card-context-banner">
                    <p>Fique em primeiro nas estatisticas, já lançou seus pitacos hoje?</p>
                    <Link to={_bets}>Meus Palpites</Link>
                </div>
                <div className="card-context-banner">
                    <p>Conheça os membros  da plataforma. Descubra os palpites lançados por eles.</p>
                    <Link to={_members}>Membros</Link>
                </div>
            </Slider>

        </div>

    )
}

export default Carousel;