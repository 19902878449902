import React, { useState } from "react";
import { Link } from "react-router-dom";
import './style.css';
import Logo from '../../assets/logo.png';
import { FiMenu } from "react-icons/fi";
import MenuMobile from '../menuMobile';
import routesReference from '../../utils/routesReference';
import { menuReference, highlight } from '../../utils/menuReference';

function Header({ optionMenu }) {

    const { _home } = routesReference;
    var [classMenu, setClassMenu] = useState("menu dn");
    var [checked, setCheckedMenu] = useState(false);

    function openMenu() {        
        if (checked) {
            setClassMenu("menu d");
          
            setTimeout(() => {
                setClassMenu("menu dn");
                setCheckedMenu(false);
            }, 500);
        } else {
            setClassMenu("menu d");
           
            setTimeout(() => {
                setClassMenu("menu d openMenu");
                setCheckedMenu(true);
            }, 300);
        }
    }

    return (
        <header className="header">

            <nav className="menu-action">
                <ul>
                    <li ><Link className="highlight" to={highlight.route}>{highlight.title}</Link></li>
                    {
                        menuReference.map((item, index) => {

                            if (optionMenu.option == index) {
                                var selected = "selected";
                            }
                            return (
                                <li><Link className={selected} to={item.route}>{item.title}</Link></li>
                            )
                        })
                    }
                </ul>
            </nav>

            <Link to={_home}>
                <img src={Logo} alt="Logo mobile" />
            </Link>

            <FiMenu className="action-menu-mobile" onClick={() => { openMenu() }} />
            <MenuMobile refClick={openMenu} refClass={classMenu} optionMenu={optionMenu} />
        </header>
    )
}

export default Header;