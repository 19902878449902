import React from "react";
import './style.css';
// import { GiGoat } from "react-icons/gi";
import { FiArrowUp } from "react-icons/fi";
import { FiArrowDown } from "react-icons/fi";
import { FaChessKing } from "react-icons/fa";

import avatarDefault from '../../../../utils/avatarGeneration';

function Ranking({ ranking }) {
    const URL = 'https://drive.google.com/uc?export=view&id=';
    var avatarDefault1 = avatarDefault();
    var avatarDefault2 = avatarDefault();
    var avatarDefault3 = avatarDefault();
    return (
        ranking ? 
        <div className="round-score-container">
            <div className="round-master">
                <div className="title">
                    <span>Mestre da rodada</span>
                </div>
                <div className="round-master-context">
                    <div className="master-avatar-container">
                        <span>1</span>
                        <div className="avatar">
                            <img src={ranking[0].idAvatar ? URL + ranking[0].idAvatar : avatarDefault1} alt="" />
                        </div>
                    </div>
                    <div className="master-context">
                        <div className="master-context-info">
                            <h3>{ranking ? ranking[0].userName : '-'}</h3>
                            <h4>{ranking ? ranking[0].userLastName : '-'}</h4>
                        </div>
                        <div className="master-context-point">
                            <div className="icon"><FaChessKing /></div>
                            {/* <div className="icon"><GiGoat /></div> */}
                            <div className="pts">
                                <FiArrowUp />
                                <span>{ranking ? ranking[0].somaPontos : '-'}</span>
                                <span>pts</span>
                            </div>
                        </div>
                        <div className="master-context-point-nd">
                            <div className="info">
                                <FiArrowDown />
                                <span>Segundo</span>
                            </div>

                            <span>{ranking ? ranking[1].userName : '-'} {ranking ? ranking[1].userLastName : '-'}</span>
                            <span>{ranking ? ranking[1].somaPontos : '-'} pts</span>
                            <span>#02</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ranking">
                <div className="podium-container">
                    <h3>Ranking Geral</h3>

                    <div className="podium-list-user">
                        {
                            ranking ? (
                                <div className="podium-user">
                                    <div className="avatar">
                                        <div className="avatar-pic">
                                            <img src={ranking[0].idAvatar ? URL + ranking[0].idAvatar : avatarDefault1} alt="" />
                                        </div>
                                        <span>1</span>
                                    </div>
                                    <div className="context">
                                        <span>{ranking[0].somaPontos} pts</span>
                                        <span>{ranking[0].userName} {ranking[0].userLastName}</span>
                                    </div>
                                </div>
                            ) : null
                        }
                        {
                            ranking ? (
                                <div className="podium-user">
                                    <div className="avatar">
                                        <div className="avatar-pic">
                                            <img src={ranking[1].idAvatar ? URL + ranking[1].idAvatar : avatarDefault2} alt="" />
                                        </div>
                                        <span>2</span>
                                    </div>
                                    <div className="context">
                                        <span>{ranking[1].somaPontos} pts</span>
                                        <span>{ranking[1].userName} {ranking[1].userLastName}</span>
                                    </div>
                                </div>
                            ) : null
                        }
                        {
                            ranking ? (
                                <div className="podium-user">
                                    <div className="avatar">
                                        <div className="avatar-pic">
                                            <img src={ranking[2].idAvatar ? URL + ranking[2].idAvatar : avatarDefault3} alt="" />
                                        </div>
                                        <span>3</span>
                                    </div>
                                    <div className="context">
                                        <span>{ranking[2].somaPontos} pts</span>
                                        <span>{ranking[2].userName} {ranking[2].userLastName}</span>
                                    </div>
                                </div>
                            ) : null
                        }

                    </div>
                </div>


                <div className="ranking-list-container">
                    <div className="ranking-header">
                        <span>Nomes</span>
                        <span>pontos</span>
                        <span>acertos</span>
                    </div>

                    <div className="ranking-body">
                        {
                            ranking ? ranking.map((item) => {
                                return (
                                    <div className="item">
                                        <span>{item.userName} {item.userLastName}</span>
                                        <span>{item.somaPontos}</span>
                                        <span>{item.somaAcertos}</span>
                                    </div>
                                );
                            }) : null
                        }
                    </div>

                </div>
            </div>

        </div>
        : null
    );
}

export default Ranking;